import * as CookieConsent from "vanilla-cookieconsent";

// Define dataLayer and the gtag function.
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

// Default ad_storage to 'denied'.
gtag('consent', 'default', {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied',
  'functionality_storage': 'denied',
  'personalization_storage': 'denied'
});

/* Helper functions to avoid repeating the same ga code */
function enableAnalytics() {
  // console.log("enabled analytics")
  gtag('consent', 'update', { 'analytics_storage': 'granted' });
}

function disableAnalytics() {
  // console.log("disabled analytics")
  gtag('consent', 'update', { 'analytics_storage': 'denied' });
  CookieConsent.eraseCookies(['_gid', /^_ga/]);
}

function enableAds() {
  // console.log("enabled ads")
  gtag('consent', 'update', {
    'ad_storage': 'granted',
    'ad_user_data': 'granted',
    'ad_personalization': 'granted'
  });
}

function disableAds() {
  // console.log("disabled ads")
  gtag('consent', 'update', {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied'
  });
  CookieConsent.eraseCookies(['_fbp', '_gcl_au']);
  console.log("disableAds");
}

window.addEventListener('load', function () {
  // obtain plugin
  // var cc = initCookieConsent();

  // run plugin with your configuration
  CookieConsent.run({
    cookie: {
      name: 'i4m_cookie',               // default: 'cc_cookie'

    },


    guiOptions: {
      consentModal: {
        layout: 'box wide',
        position: 'bottom right',
        flipButtons: true,
        equalWeightButtons: false
      }
    },
    onFirstAction: function (user_preferences, cookie) {
      // callback triggered only once
    },

    // onAccept: function (cookie) {
    //     // ...
    // },

    onChange: ({ cookie, changedCategories, changedServices }) => {
      // do something
      if (CookieConsent.allowedCategory('analytics')) {
        enableAnalytics();
      } else {
        disableAnalytics();
      }

      if (CookieConsent.allowedCategory('ads')) {
        enableAds();
      } else {
        disableAds();
      }
    },


    // onAccept: function (cookie) {
    //     if (cc.allowedCategory('analytics')) {
    //         enableAnalytics();
    //     }

    //     if (cc.allowedCategory('ads')) {
    //         enableAds();
    //     }
    // },

    // onChange: function (cookie, changed_categories) {
    //     if (cc.allowedCategory('analytics')) {
    //         enableAnalytics();
    //     } else {
    //         disableAnalytics();
    //     }

    //     if (cc.allowedCategory('ads')) {
    //         enableAds();
    //     } else {
    //         disableAds();
    //     }
    //     // Refresh the page
    //     // window.location.reload();
    // },

    mode: 'opt-out',
    categories: {
      necessary: {
        enabled: true,  // this category is enabled by default
        readOnly: true  // this category cannot be disabled
      },
      analytics: {
        enabled: true,
        autoClear: {
          cookies: [
            {
              name: /^_ga/,   // regex: match all cookies starting with '_ga'
            },
            {
              name: '_gid',   // string: exact cookie name
            }
          ]
        },

        // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
        services: {
          ga: {
            label: 'Google Analytics',
            onAccept: () => {
              enableAnalytics();
            },
            onReject: () => {
              disableAnalytics();
            }
          },
          youtube: {
            label: 'Youtube Embed',
            onAccept: () => {
              enableAnalytics();
            },
            onReject: () => {
              disableAnalytics();
            }
          },
        }
      },
      ads: {
        autoClear: {
          cookies: [
            // {
            //   name: '_fbp',   // regex: match all cookies starting with '_ga'
            //   domain: 'grid-labs.com'
            // },
            // {
            //   name: '_gcl_au',   // regex: match all cookies starting with '_ga'
            //   domain: 'grid-labs.com'
            // }
          ],
          reloadPage: true
        },

        // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
        services: {
          fb: {
            label: 'Facebook',
            onAccept: () => {
              enableAds();
            },
            onReject: () => {
              disableAds();
            }
          }
        }
      }
    },


    language: {
      default: 'fr',
      autoDetect: "document",
      translations: {
        fr: {
          consentModal: {
            title: 'Ce site utilise des cookies.',
            description: 'Nous utilisons des cookies pour assurer le bon fonctionnement de notre site internet et pour vous offrir la meilleure expérience possible. Vous avez le choix de les accepter ou de les refuser.',
            acceptAllBtn: 'Accepter',
            acceptNecessaryBtn: 'Rejeter',
            showPreferencesBtn: 'Personnaliser'
          },
          preferencesModal: {
            title: 'Préférences des cookies',
            // description: 'Les cookies nous permettent de personnaliser le contenu et les annonces, d\'offrir des fonctionnalités relatives aux médias sociaux et d\'analyser notre trafic. Nous partageons également des informations sur l\'utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d\'analyse, qui peuvent combiner celles-ci avec d\'autres informations que vous leur avez fournies ou qu\'ils ont collectées lors de votre utilisation de leurs services.',
            acceptAllBtn: 'Tout accepter',
            acceptNecessaryBtn: 'Tout rejeter',
            savePreferencesBtn: 'Accepter la sélection',
            closeIconLabel: 'Fermer la fenêtre',
            sections: [
              {
                title: 'Utilisation des cookies',
                description: 'Les cookies nous permettent de personnaliser le contenu et les annonces, d\'offrir des fonctionnalités relatives aux médias sociaux et d\'analyser notre trafic. Nous partageons également des informations sur l\'utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d\'analyse, qui peuvent combiner celles-ci avec d\'autres informations que vous leur avez fournies ou qu\'ils ont collectées lors de votre utilisation de leurs services.',
              },
              {
                title: 'Cookies de fonctionnement',
                description: 'Les cookies de fonctionnement contribuent à rendre un site web utilisable en activant des fonctions de base comme la navigation de page et l\'accès aux zones sécurisées du site web. Le site web ne peut pas fonctionner correctement sans ces cookies, ils ne peuvent donc pas être désélectionnés.',

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: 'necessary'
              },
              {
                title: 'Mesure d\'audience et web analyse',
                description: 'Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d\'améliorer les performances de notre site Web. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web. Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous n\'acceptez pas ces cookies, nous ne serons pas informé de votre visite sur notre site.',
                linkedCategory: 'analytics'
              },
              {
                title: 'Marketing',
                description: 'Ces cookies peuvent être mis en place au sein de notre site Web par nos partenaires publicitaires. Ils peuvent être utilisés par ces sociétés pour établir un profil de vos intérêts et vous proposer des publicités pertinentes sur d\'autres sites Web. Ils ne stockent pas directement des données personnelles, mais sont basés sur l\'identification unique de votre navigateur et de votre appareil Internet. Si vous n\'autorisez pas ces cookies, votre publicité sera moins ciblée.',
                linkedCategory: 'ads'
              },
              {
                title: 'Plus d’informations',
                description: 'Pour plus d\'informations concernant notre Politique de Cookies, <a href="/nous-contacter/">contactez-nous</a>.'
              }
            ]
          }
        },
        en: {
          consentModal: {
            title: 'This website uses cookies.',
            description: 'We use cookies to ensure the smooth running of our website and to provide you with the best possible experience. You can choose to accept or decline them.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage preferences'
          },
          preferencesModal: {
            title: 'Cookie usage',
            // description: 'Cookies enable us to personalize content and ads, offer social media features and analyze our traffic. We also share information about the use of our site with our social media, advertising and analytics partners, who may combine this with other information you have provided to them or that they have collected through your use of their services.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Accept current selection',
            closeIconLabel: 'Close modal',
            sections: [
              {
                title: 'Consent management',
                description: 'Cookies enable us to personalize content and ads, offer social media features and analyze our traffic. We also share information about the use of our site with our social media, advertising and analytics partners, who may combine this with other information you have provided to them or that they have collected through your use of their services.',
              },
              {
                title: 'Functional cookies',
                description: 'Functional cookies help make a website usable by enabling basic functions such as page navigation and access to secure areas of the website. The website cannot function properly without these cookies, so they cannot be deselected.',

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: 'necessary'
              },
              {
                title: 'Audience measurement and web analysis',
                description: 'These cookies enable us to determine the number of visits and sources of traffic, in order to measure and improve the performance of our website. They also help us to identify the most/least visited pages and to evaluate how visitors navigate the website. All information collected by these cookies is aggregated and therefore anonymized. If you do not accept these cookies, we will not be informed of your visit to our site.',
                linkedCategory: 'analytics'
              },
              {
                title: 'Marketing',
                description: 'These cookies may be set on our website by our advertising partners. They may be used by these companies to profile your interests and provide you with relevant advertising on other websites. They do not directly store personal data, but are based on the unique identification of your browser and Internet device. If you do not authorize these cookies, your advertising will be less targeted.',
                linkedCategory: 'ads'
              },
              {
                title: 'More information',
                description: 'For more information about our Cookie Policy, <a href="/en/contact-us/">contact us</a>.'
              }
            ]
          }
        }
      }
    }
  });

});